import axios from "axios";
export default {
  namespaced: true,
  state: {},
  mutations: {
    getMosaics(state, value) {
      state.mosaics = value;
    },
    createMosaic(state, value) {
      state.createMosaic = value;
    },
  },
  actions: {
    async createMosaic({ commit, dispatch }, { tileSet, file }) {
      console.log("CREATE MOSAIC");
      const formData = new FormData();
      formData.append("tileSet", tileSet);
      formData.append("file", file);
      console.log("FILE", file);
      axios
        .post(`${process.env.VUE_APP_API_URL}/mosaic/create`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => commit("createMosaic", response.data.data))
        .catch((err) => dispatch("general/handleError", err, { root: true }));
    },
  },
  getters: {
    getMosaics(state) {
      return state.mosaics;
    },
  },
};
