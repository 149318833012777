export default {
  navItem: {
    mosaic: "Mosaic",
  },
  mosaic: {
    tileSets: "Tile Sets",
    create: "Create Mosaic",
    gallery: "Gallery",
    tileSet: {
      name: "Name",
      nameRequired: "Enter a name for the tile set",
      uploadTitle: "Drag images here or click to add",
      uploadButton: "Upload images",
    },
  },
  message: "hello",
  cv: {
    contact: "Contact",
    professional: "Professional",
    education: "Education",
    languages: "Languages: ",
    frameworks: "Frameworks: ",
    tools: "Tools: ",
    dbs: "Databases: ",
    projects: "Developer Projects: ",
    devops: "Devops Tasks: ",
    schumacher: {
      timeline: "Mar 2024 — present",
      position: "Fullstack Web Developer | DevOps",
      description:
        "Developing custom web applications according to client specification, including deployment and hosting",
      p1: "custom ERP invoicing tool for niche requirements in elderly care consultation and training sector",
    },
    vrtualx: {
      position: "Fullstack Web Developer | DevOps",
      description:
        "IT company focused on VR experiences for businesses and virtual events, conferences, expositions",
      p1: "platform for artists to showcase artworks, feeding into public facing portal for artist evaluation and prognosis. Implementation of Distributed System",
      p2: "building a customer- and project-based CMS; goal: setting up content through a web frontend but to be consumed through different means, i.e. Web, VR, Mobile",
      p3: "ePoster tool; goal: serving posters alongside audio for scientific conferences",
      p4: "integration of various video conference tools (Zoom, Jitsi) into an Event CMS; goal: provide possibilities for visitors of virtual events to interact with exhibitors and each other",
      deploy: "Full Deployment Cycle:",
      d1: "setup cloud server",
      d2: "register domain",
      d3: "CI pipeline deployment via Gitlab Runner",
      d4: "reverse proxy",
      d5: "DB backups",
      admin: "Gitlab Administration",
    },
    fleno: {
      position: "Fullstack Web Developer",
      description: "Software agency for custom web applications and eCommerce",
      frameworks: "proprietary PHP framework, MVC pattern",
      p1: "Wind Park Certification Client - calculate efficiency of (planned) wind parks; goal: streamline certification and building permissions process while providing approachable UX",
      p2: "online shops and logistics company joint venture - platform to self-order international shipment",
      p3: "several Magento online shops - setting up and maintaining shops of long term clients, implementing extensions",
    },
    bachelor: {
      title: "Bachelor of Applied Informatics",
      timeline: "Sep 2017 — May 2024",
      uni: " HS Flensburg ",
      major: "majoring in Web- and Software Technologies",
      projects: "completed projects with:",
      thesis: "Thesis / final project:",
      thesisTitle:
        '"Digitisation of customer administration and accounting of a micro-enterprise in the care sector"',
    },
    training: {
      title: "Ausbildung: IT specialist for application development",
      timeline: "Aug 2014 — Jan 2017",
      school: " BBZ Rendsburg-Eckernförde ",
      description: "Dual training between school and company",
      short: "Graduated in shortened time due to outstanding performance",
      honours: "Graduated with honours as best in year (profession and region)",
    },
  },
};
