<template>
  <v-card elevation="20">
    <v-card-title>
      {{ $t("mosaic.tileSets") }}
    </v-card-title>
    <v-card-text>
      <v-text-field
        v-model="name"
        :label="$t('mosaic.tileSet.name')"
        outlined
        dense
        required
        @input="$v.name.$touch()"
        @blur="$v.name.$touch()"
        :error-messages="
          $v.name.$dirty && !$v.name.required
            ? [$t('mosaic.tileSet.nameRequired')]
            : []
        "
      >
      </v-text-field>
      <upload-files :add-files="addFiles" field-id="tileSetUpload" />
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="primary" text @click="save">{{ $t("save") }}</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import UploadFiles from "@/components/upload/UploadFiles";
import { mapActions } from "vuex";
export default {
  name: "TileSets",
  components: { UploadFiles },
  data() {
    return {
      name: "",
      files: [],
    };
  },
  validations: {
    name: {
      required,
    },
  },
  computed: {},
  methods: {
    ...mapActions({
      createTileSet: "tileSet/createTileSet",
    }),
    addFiles(files) {
      this.files = files;
    },
    async save() {
      this.$v.$touch();
      if (!this.$v.$invalid && this.files.length > 0) {
        await this.createTileSet({ name: this.name, files: this.files });
      }
    },
  },
  mounted() {},
  beforeDestroy() {},
};
</script>

<style lang="scss" scoped></style>
