const initialState = () => {
  return {
    token: "",
  };
};

export default {
  namespaced: true,
  state: initialState(),
  mutations: {},
  actions: {},
  getters: {},
};
