import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    dark: true,
    themes: {
      dark: {
        primary: "#ffffff",
        secondary: "#a18109",
        accent: "#00bbff",
        success: "#1fb20c",
        info: "#f34fc6",
        warning: "#FB8C00",
        error: "#FF5252",
        background: "#4d4d4d",
        buttonText: "#000000",
        overlayBackground: "#313131",
        imageBackground: "#ffffff",
        accentDark: "#054594",
      },
      light: {
        primary: "#2F1160",
        secondary: "#6200FF",
        accent: "#00bbff",
        success: "#1fb20c",
        info: "#f34fc6",
        warning: "#FB8C00",
        error: "#FF5252",
        background: "#ffffff",
        buttonText: "#ffffff",
        overlayBackground: "#e5daf8",
        imageBackground: "#cccccc",
        inactive: "#4d4d4d",
        accentDark: "#054594",
      },
    },
  },
});
