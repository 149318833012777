export default {
  navItem: {
    mosaic: "Mosaik",
  },
  mosaic: {
    tileSets: "Kachelsets",
    create: "Mosaik erstellen",
    gallery: "Gallerie",
    tileSet: {
      name: "Name",
      nameRequired: "Gib einen Namen für das Kachelsegt ein",
      uploadTitle: "Drag images here or click to add",
      uploadButton: "Upload images",
    },
  },
  message: "Moin!",
  cv: {
    contact: "Kontakt",
    professional: "Berufserfahrung",
    education: "Bildung",
    languages: "Sprachen: ",
    frameworks: "Frameworks: ",
    tools: "Werkzeuge: ",
    dbs: "Datenbanken: ",
    projects: "Entwickler Projekte: ",
    devops: "DevOps Tasks: ",
    schumacher: {
      timeline: "Mär 2024 — heute",
      position: "Fullstack Web Developer | DevOps",
      description:
        "Entwicklung kundenspezifischer Webanwendungen nach Kundenspezifikation, einschließlich Deployment und Hosting",
      p1: "Maßgeschneidertes ERP-Abrechnungstool für Nischenanforderungen im Bereich Altenpflegeberatung und -schulung",
    },
    vrtualx: {
      position: "Fullstack Web Developer | DevOps",
      description:
        "IT-Unternehmen mit Schwerpunkt auf VR Anwendungen und 360° Produktion im B2B Bereich sowie virtuellen Veranstaltungen, Konferenzen, Ausstellungen, Showrooms (web-basiert)",
      p1: "Plattform für Künstler, ihre Kunstwerke auszustellen, um damit ein öffentliches Portal für Künstlerbewertung und -prognose zu füttern. Implementierung als verteiltes System",
      p2: "Entwicklung eines Decoupled CMS zur Verwaltung von Kundenprojekten; Ziel: über ein Web Frontend Inhalte einrichten, die anschließend in Web, VR oder Mobile konsumiert werden können",
      p3: "ePoster Tool; Ziel: Bereitstellung von Postern und Audiokommentar für wissenschaftliche Konferenzen",
      p4: "Integration verschiedener Videokonferenz-Tools (Zoom, Jitsi) in ein Event-CMS; Ziel: den Besuchern virtueller Veranstaltungen die Möglichkeit geben, miteinander zu interagieren",
      deploy: "vollständiger Entwicklungszyklus:",
      d1: "Cloudserver einrichten",
      d2: "Domains registrieren",
      d3: "CI pipeline anlegen",
      d4: "Reverse Proxy einrichten",
      d5: "DB backups anlegen",
      admin: "Gitlab Administration",
    },
    fleno: {
      position: "Fullstack Web Developer",
      description:
        "Software-Agentur für individuelle Webanwendungen und Online-Shops",
      frameworks: "proprietäres PHP Framework, MVC pattern",
      p1: "Windpark-Zertifizierungs-Client - Berechnung der Effizienz von (geplanten) Windparks; Ziel: Optimieren Sie den Zertifizierungs- und Baugenehmigungsprozess und bieten Sie gleichzeitig eine zugängliche Benutzeroberfläche",
      p2: "Joint Venture von Online-Shops und Logistikunternehmen: Plattform zur Selbstbestellung internationaler Sendungen",
      p3: "mehrere Magento-Onlineshops: Einrichtung und Pflege von Shops langjähriger Kunden",
    },
    bachelor: {
      title: "Bachelor in Angewandter Informatik",
      timeline: "Sep 2017 — Mai 2024",
      uni: " HS Flensburg ",
      major: "Schwerpunkt in Web- and Software Technologies",
      projects: "abgeschlossene Projekte mit:",
      thesis: "Thesis / Abschlussarbeit:",
      thesisTitle:
        '"Digitalisierung von Kundenverwaltung und Rechnungswesen eines Kleinstunternehmens im pflegenden Sektor"',
    },
    training: {
      title: "Ausbildung: Fachinformatiker für Anwendungsentwicklung",
      timeline: "Aug 2014 — Jan 2017",
      school: " BBZ Rendsburg-Eckernförde ",
      description: "",
      short: "Abschluss in verkürzter Zeit aufgrund hervorragender Leistungen",
      honours:
        "Abschluss mit Auszeichnung als Jahrgangsbester in Region und Beruf",
    },
  },
};
