<template>
  <v-card elevation="20">
    <v-card-title>
      {{ $t("mosaic.gallery") }}
    </v-card-title>
    <v-card-text></v-card-text>
    <v-card-actions></v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "MosaicGallery",
  components: {},
  data() {
    return {};
  },
  computed: {},
  mounted() {},
  beforeDestroy() {},
  methods: {},
};
</script>

<style lang="scss" scoped></style>
