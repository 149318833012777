export default {
  namespaced: true,
  state: {
    drawer: false,
    nav: [
      {
        title: "home",
        icon: "mdi-home-account",
        route: "/",
        label: "home",
      },
      {
        title: "mosaic",
        icon: "mdi-checkerboard",
        route: "/mosaic",
        label: "mosaic",
      },
    ],
  },
  mutations: {
    showDrawer(state, show) {
      state.drawer = show;
    },
  },
  actions: {
    handleError(context, error) {
      console.log(error);
    },
  },
  getters: {
    getDrawer(state) {
      return state.drawer;
    },
  },
};
