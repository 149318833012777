<template>
  <v-container mt-0>
    <v-tabs grow>
      <v-tab>{{ $t("mosaic.tileSets") }}</v-tab>
      <v-tab>{{ $t("mosaic.create") }}</v-tab>
      <v-tab>{{ $t("mosaic.gallery") }}</v-tab>
      <v-tab-item><tile-sets /></v-tab-item>
      <v-tab-item><create-mosaic /></v-tab-item>
      <v-tab-item><mosaic-gallery /></v-tab-item>
    </v-tabs>
  </v-container>
</template>

<script>
import TileSets from "@/components/mosaic/TileSets";
import CreateMosaic from "@/components/mosaic/CreateMosaic";
import MosaicGallery from "@/components/mosaic/MosaicGallery";
import { mapActions } from "vuex";
export default {
  name: "MosaicView",
  components: { MosaicGallery, CreateMosaic, TileSets },
  methods: {
    ...mapActions({
      loadTileSetNames: "tileSet/getTileSetNames",
    }),
  },
  mounted() {
    this.loadTileSetNames();
  },
};
</script>
