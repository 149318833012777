import axios from "axios";
export default {
  namespaced: true,
  state: {},
  mutations: {
    getTileSets(state, value) {
      state.tileSets = value;
    },
    createTileSet(state, value) {
      state.createTileSet = value;
    },
  },
  actions: {
    async createTileSet({ commit, dispatch }, { name, files }) {
      const formData = new FormData();
      formData.append("name", name);
      files.forEach((f) => formData.append("files", f));
      axios
        .post(`${process.env.VUE_APP_API_URL}/mosaic/tileSet`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => commit("createTileSet", response.data.data))
        .catch((err) => dispatch("general/handleError", err, { root: true }));
    },
    async getTileSetNames({ commit, dispatch }) {
      axios
        .get(`${process.env.VUE_APP_API_URL}/mosaic/tileSets`)
        .then((response) => {
          commit("getTileSets", response.data.data);
        })
        .catch((err) => dispatch("general/handleError", err, { root: true }));
    },
  },
  getters: {
    getTileSets(state) {
      return state.tileSets;
    },
  },
};
