import Vue from "vue";
import Vuex from "vuex";

import general from "./general";
import tileSet from "./tileSet";
import user from "./user";
import mosaic from "./mosaic";

Vue.use(Vuex);

const modules = {
  general,
  tileSet,
  user,
  mosaic,
};

const set = (obj, property, value) => {
  const prop = property.shift();
  if (!obj[prop]) {
    Vue.set(obj, prop, {});
  }
  if (!property.length) {
    if (value && typeof value === "object" && !Array.isArray(value)) {
      if (value instanceof File) {
        obj[prop] = value;
      } else {
        obj[prop] = { ...obj[prop], ...value };
      }
    } else {
      obj[prop] = value;
    }
    return;
  }
  set(obj[prop], property, value);
};

const del = (obj, property) => {
  const prop = property.shift();
  if (!obj[prop]) {
    return;
  }
  if (!property.length) {
    Vue.delete(obj, prop);
    return;
  }
  del(obj[prop], property);
};

const get = (obj, property) => {
  const prop = property.shift();
  if (!obj[prop] || !property.length) {
    return obj[prop];
  }
  return get(obj[prop], property);
};

export default new Vuex.Store({
  modules,
  state: {},
  getters: {
    getVal: (state) => (property) => {
      return get(state, property);
    },
  },
  mutations: {
    setVal(state, { property, value }) {
      set(state, property, value);
    },
    delVal(state, property) {
      del(state, property);
    },
    reset(state) {
      const m = Object.keys(modules);
      Object.keys(state)
        .filter((s) => !m.includes(s))
        .forEach((k) => Vue.delete(state, k));
    },
  },
  actions: {
    resetAllState({ commit }) {
      commit("reset");
      for (const currentModule in modules) {
        commit(`${currentModule}/reset`, { root: true });
      }
    },
  },
});
